<template>
  <div class="row justify-content-between">
    <div class="col-auto">
      <select v-model="value.porPagina" v-on:change="onChangePerPage()" class="form-select" style="width: 80px;">
        <option v-for="quantidade in value.quantidades" v-bind:key="quantidade.valor" v-bind:value="quantidade.valor">{{quantidade.nome}}</option>
      </select>
    </div>
    <div class="col-auto">
      <nav v-if="value.porPagina > 0" aria-label="Page navigation example">
        <ul class="pagination">
          <li v-bind:class="{ disabled: (value.pagina === 1) }" class="page-item">
            <a v-on:click.prevent="goFirstPage()" class="page-link" href="#" aria-label="First">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-bind:class="{ disabled: (value.pagina === 1) }" class="page-item">
            <a v-on:click.prevent="goPage(value.pagina-1)" class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">Anterior</span>
            </a>
          </li>
          <li v-for="pagina in linkPaginas" v-bind:key="pagina" v-bind:class="{ active: (value.pagina === pagina) }" class="page-item">
            <a v-on:click.prevent="goPage(pagina)" class="page-link" href="#">{{pagina}}</a>
          </li>
          <li v-bind:class="{ disabled: (value.pagina === totalPages) }" class="page-item">
            <a v-on:click.prevent="goPage(value.pagina+1)" class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">Próxima</span>
            </a>
          </li>
          <li v-bind:class="{ disabled: (value.pagina === totalPages) }" class="page-item">
            <a v-on:click.prevent="goLastPage()" class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Paginador',
  props: ["value"],
  computed: {
    totalPages: function() {
      if (this.value.porPagina === 0) {
        return 1;
      }

      return Math.ceil(this.value.totalItens / this.value.porPagina) || 1;
    },
    linkPaginas: function() {
      const paginas = [];
      const totalPages = this.totalPages;
      let minimo = 1;
      let maximo = totalPages;

      if (totalPages > 5) {
        maximo = (this.value.pagina + 2 <= totalPages) ? this.value.pagina + 2 : totalPages;
        if (maximo < 5) {
          maximo = 5;
        }
        minimo = maximo - 4;
      }

      for (let i = minimo; i <= maximo; i++) {
        paginas.push(i);
      }

      return paginas;
    }
  },
  methods: {
    goNextPage: function() {
      this.value.pagina += 1;
      this.emitChanges();
    },
    goPreviousPage: function() {
      this.value.pagina -= 1;
      this.emitChanges();
    },
    goFirstPage: function() {
      this.value.pagina = 1;
      this.emitChanges();
    },
    goLastPage: function() {
      this.value.pagina = this.totalPages;
      this.emitChanges();
    },
    goPage: function(page) {
      this.value.pagina = page;
      this.emitChanges();
    },
    defineParams: function() {
      this.value.requestParamsFirst = `paginate.page=1&paginate.limit=${this.value.porPagina}`;
      this.value.requestParams = `paginate.page=${this.value.pagina}&paginate.limit=${this.value.porPagina}`;
    },
    emitChanges: function() {
      this.defineParams();
      this.$emit('change-paginacao');
    },
    onChangePerPage: function() {
      this.value.pagina = 1;
      this.emitChanges();
    },
  },
  created() {
    this.defineParams();
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-item.disabled .page-link {
    color: #a6abaf;
}
</style>
