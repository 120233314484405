const model = {
    paginador: {
        pagina: 1,
        porPagina: 10,
        totalItens: 0,
        quantidades: [
            {'nome': '10', 'valor': 10},
            {'nome': '25', 'valor': 25},
            {'nome': '50', 'valor': 50},
            {'nome': '100', 'valor': 100},
            {'nome': 'Tudo', 'valor': 0},
        ],
        requestParams: 'paginate.page=1&paginate.limit=10',
    },
}

export default model
