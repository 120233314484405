<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Clientes</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-model="search.q"
              v-on:keyup.enter="handlePesquisa(true)"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa(true)" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
          <button v-on:click="handleBtnSwitchFilters()" class="btn btn-outline-secondary dropdown-toggle" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'filter']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div v-if="(search.showFilters)" class="row g-0 justify-content-end mb-3">
        <div class="col-12 col-sm-6 border border-secondary rounded px-2">
          <div class="mb-2">
            <label>Status</label>
            <select v-model="search.status" class="form-select">
              <option value="&active=true">Apenas ativos</option>
              <option value="&active=false">Apenas inativos</option>
              <option value="">Todos</option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Nome / razão social</th>
              <th class="d-none d-md-table-cell">Login</th>
              <th class="d-none d-md-table-cell">Telefone</th>
              <th class="d-none d-sm-table-cell">Endereço</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customers" :key="customer.id" @click="handleViewCustomer(customer)" :class="cssRow(customer)" class="cursor-pointer">
                <td scope="row">
                  {{customer.name}}
                  <font-awesome-icon v-if="(customer.obs)" :icon="['far', 'comment']" fixed-width class="me-1"/>
                  <span class="d-sm-none text-muted-2 fst-italic small"><br>{{ customerLogin(customer.id) }}</span>
                </td>
                <td class="d-none d-md-table-cell text-muted-2 fst-italic">
                  {{ customerLogin(customer.id) }}
                </td>
                <td class="d-none d-md-table-cell">{{shared.customer.phonesReadable(customer.phones)}}</td>
                <td class="d-none d-sm-table-cell">
                  <span v-if="!!customer.addresses && customer.addresses.length > 0">
                    {{shared.customer.addressReadable(customer.addresses[0])}}
                  </span>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import model from '@/model'

export default {
  name: 'Customers',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      search: {
        q: '',
        showFilters: false,
        status: '&active=true',
      },

      customers: [],
      ispSubscriptions: [],
    }
  },
  methods: {
    handlePesquisa(resetPage) { /* PS: ALTERAÇÕES NESTE TRECHO, REPLICAR PARA PesquisaClientes.vue:handlePesquisa */
      const q = this.search.q;
      const status = this.search.status;
      let params = this.paginador.requestParams;
      if (resetPage) {
        params = this.paginador.requestParamsFirst;
      }

      loading(true);
      api.get(`/customers?${params}&orderBy[name]&q=${q}&with=Addresses${status}`).then(res => {
        loading(false);
        this.customers = res.data.items;
        this.ispSubscriptions = res.data.aggregators.isp_subscriptions;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleBtnSwitchFilters() {
      this.search.showFilters = !this.search.showFilters;
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.$router.push(`/clientes/novo`);
    },

    async handleViewCustomer(customer) {
      this.$router.push(`/clientes/${customer.id}`);
    },

    cssRow(customer) {
      if (customer.active) {
        return ['text-info'];
      }

      return ['text-muted'];
    },

    customerLogin(id) {
      const logins = this.ispSubscriptions.filter(x => x.customerId === id).map(x => x.login);

      return logins.join(', ') || '-';
    },
  },
  computed: {
    shared() {
      return shared;
    },
  },
  created() {
    this.handlePesquisa();
  }
}
</script>
